// src/MacrosCalculator.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const MacrosCalculator = () => {
  const [calories, setCalories] = useState('');
  const [carbPercentage, setCarbPercentage] = useState('');
  const [fatPercentage, setFatPercentage] = useState('');
  const [proteinPercentage, setProteinPercentage] = useState('');

  const [results, setResults] = useState({
    carbs: 0,
    fats: 0,
    proteins: 0,
  });

  const calculateMacros = () => {
    const totalCalories = parseFloat(calories);
    const carbPercent = parseFloat(carbPercentage);
    const fatPercent = parseFloat(fatPercentage);
    const proteinPercent = parseFloat(proteinPercentage);

    if (totalCalories && carbPercent && fatPercent && proteinPercent) {
      const carbs = (totalCalories * (carbPercent / 100)) / 4;
      const fats = (totalCalories * (fatPercent / 100)) / 9;
      const proteins = (totalCalories * (proteinPercent / 100)) / 4;

      setResults({
        carbs: carbs.toFixed(2),
        fats: fats.toFixed(2),
        proteins: proteins.toFixed(2),
      });
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4">
        <Helmet>
        <title>Macros Calculator</title>
        <meta
          name="description"
          content="Calculate your Daily Macro Needs"
        />
        <meta name="keywords" content="Macros Calculator" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/macros-calculator" />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Macros Calculator</h1>
      <div className="mb-4">
        <label className="block mb-2">
          Total Calories:
          <input
            type="number"
            value={calories}
            onChange={(e) => setCalories(e.target.value)}
            className="border p-2 w-full"
          />
        </label>
        <label className="block mb-2">
          Carbohydrates (% of total calories):
          <input
            type="number"
            value={carbPercentage}
            onChange={(e) => setCarbPercentage(e.target.value)}
            className="border p-2 w-full"
          />
        </label>
        <label className="block mb-2">
          Fats (% of total calories):
          <input
            type="number"
            value={fatPercentage}
            onChange={(e) => setFatPercentage(e.target.value)}
            className="border p-2 w-full"
          />
        </label>
        <label className="block mb-4">
          Proteins (% of total calories):
          <input
            type="number"
            value={proteinPercentage}
            onChange={(e) => setProteinPercentage(e.target.value)}
            className="border p-2 w-full"
          />
        </label>
        <button
          onClick={calculateMacros}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Calculate
        </button>
      </div>
      <div>
        <h2 className="text-xl font-bold">Results:</h2>
        <p>Carbohydrates: {results.carbs} grams</p>
        <p>Fats: {results.fats} grams</p>
        <p>Proteins: {results.proteins} grams</p>
      </div>
    </div>
  );
};

export default MacrosCalculator;
