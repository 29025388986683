import React, { useState, useRef, useEffect } from 'react';
import './App.css'; // Import your Tailwind CSS or other styles here
import { Helmet } from 'react-helmet';

const flags = {
  USD: '/flags/us.png',
  INR: '/flags/in.png',
  GBP: '/flags/uk.png',
  GER: '/flags/ge.png',
  SGD: '/flags/sg.png',
  TWD: '/flags/tw.png',
  CAD: '/flags/ca.png'
};

const CurrencyDropdown = ({ onCurrencySelect, selectedCurrency }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null); // Create a ref for the dropdown container

  const currencies = [
    { code: 'USD', name: 'USD' },
    { code: 'INR', name: 'INR' },
    { code: 'GBP', name: 'GBP' },
    { code: 'GER', name: 'GER' },
    { code: 'SGD', name: 'SGD' },
    { code: 'TWD', name: 'TWD' },
    { code: 'CAD', name: 'CAD' }
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectCurrency = (currency) => {
    onCurrencySelect(currency);
    setIsOpen(false);
  };

  const filteredCurrencies = currencies.filter(currency =>
    currency.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative group">
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
      >
        <span className="mr-2 flex items-center">
          <img
            src={flags[selectedCurrency] || flags['USD']}
            alt={selectedCurrency}
            className="w-6 h-6 mr-2"
          />
          {selectedCurrency}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 ml-2 -mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
            type="text"
            placeholder="Select Currency"
            autoComplete="off"
          />
          {filteredCurrencies.map(currency => (
            <button
              key={currency.code}
              onClick={() => handleSelectCurrency(currency.code)}
              className="block w-full px-4 py-2 flex items-center text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
            >
              <img
                src={flags[currency.code]}
                alt={currency.code}
                className="w-6 h-6 mr-2"
              />
              {currency.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const EMICalculator = () => {
  const [totalAmount, setTotalAmount] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [months, setMonths] = useState(12);
  const [interestRate, setInterestRate] = useState(5);
  const [emi, setEmi] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [showInterest, setShowInterest] = useState(false);
  const [currency, setCurrency] = useState('INR');

  const formatNumber = (value) => {
    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommas = (value) => {
    return value ? value.replace(/,/g, '') : '';
  };

  const calculateEMI = (loanAmount, rate, months) => {
    const monthlyInterestRate = rate / 12 / 100;
    return (
      (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, months)) /
      (Math.pow(1 + monthlyInterestRate, months) - 1)
    );
  };

  useEffect(() => {
    if (!totalAmount || !months || !interestRate || interestRate > 100 || parseFloat(totalAmount) <= parseFloat(downPayment)) {
      setEmi(null);
      setTotalInterest(null);
      return;
    }

    const loanAmount = parseFloat(removeCommas(totalAmount)) - parseFloat(removeCommas(downPayment));
    const monthsValue = parseFloat(months);

    const calculatedEMI = calculateEMI(loanAmount, interestRate, monthsValue);
    const totalRepayment = calculatedEMI * monthsValue;
    const accumulatedInterest = totalRepayment - loanAmount;

    setEmi(calculatedEMI.toFixed(2));
    setTotalInterest(accumulatedInterest.toFixed(2));
  }, [totalAmount, downPayment, months, interestRate]);

  return (
    <div className="max-w-lg mx-auto p-4 border rounded shadow-lg">
        <Helmet>
        <title>EMI Calculator</title>
        <meta
          name="description"
          content="View, Sort and Download CSV."
        />
        <meta name="keywords" content="CSV Viewer" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/csv-viewer" />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4">EMI Calculator</h2>
      <CurrencyDropdown onCurrencySelect={setCurrency} selectedCurrency={currency} />
      <form>
        <div className="mb-4">
          <label className="input block text-gray-700">Total Amount</label>
          <input
            type="text"
            value={formatNumber(totalAmount)}
            onChange={(e) => setTotalAmount(removeCommas(e.target.value))}
            className="w-full mt-1 p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Down Payment</label>
          <input
            type="text"
            value={formatNumber(downPayment)}
            onChange={(e) => setDownPayment(removeCommas(e.target.value))}
            className="w-full mt-1 p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Months: {months}</label>
          <input
            type="text"
            min="1"
            max="360"
            value={months}
            onChange={(e) => setMonths(e.target.value)}
            className="w-full mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Interest Rate (%) / Year</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            className="w-full mt-1 p-2 border border-gray-300 rounded"
            required
            max={100}
          />
        </div>
      </form>

      {emi && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Results:</h3>
          <div className="mb-2 p-4 bg-green-100 text-green-700 rounded">
            <p className="text-2xl">EMI: {currency} {formatNumber(emi)} per month</p>
          </div>

          <div className="mt-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={showInterest}
                onChange={(e) => setShowInterest(e.target.checked)}
              />
              Show Accumulated Interest
            </label>
          </div>

          {showInterest && ( 
            <div className="mt-4">
              <div className="mb-2 p-4 bg-blue-100 text-blue-700 rounded">
                <p className="text-xl">Total Interest Accumulated: {currency} {formatNumber(totalInterest)}</p>
              </div>
              <div className="mb-2 p-4 bg-yellow-100 text-yellow-700 rounded">
                <p className="text-xl">Total Payment (Principal + Interest): {currency} {formatNumber((parseFloat(emi) * months).toFixed(2))}</p>
              </div>
              <div className="mb-2 p-4 bg-purple-100 text-purple-700 rounded">
                <p className="text-xl">Total Amount Including Down Payment: {currency} {formatNumber((parseFloat(totalInterest) + parseFloat(removeCommas(totalAmount))).toFixed(2))}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const App = () => {
  return (
    <div className="App">
      <EMICalculator />
    </div>
  );
};

export default App;
