// src/Cards.js
import React from "react";
import { Link } from "react-router-dom";
import csvLogo from './thumbnails/CSV.png' 
import EmiLogo from './thumbnails/EMI.png' 
import calorieLogo from './thumbnails/calorie.png'
import photoLogo from './thumbnails/Photo.png'
import nsLogo from './thumbnails/nslookup.png'
import snapLogo from './thumbnails/snapcode.png'
import macrosLogo from './thumbnails/macros.png'
import hostingLogo from './thumbnails/hosting.png'
import statusLogo from './thumbnails/status.png'

import "./App.css";

const Card = ({ title, path, description, logo }) => (
  <div className="bg-white text-center shadow-md rounded-lg p-4 w-64 h-64 flex flex-col justify-between">
    <div>
      <img src={logo} alt={`${title} Logo`} className="h-auto w-full rounded-md mb-10" />
      <Link to={path} className="text-xl font-bold hover:text-blue-600">
        {title}
      </Link>
    </div>
  </div>
);

const Cards = () => {
  return (
    <div className="flex justify-center flex-wrap gap-10">
    <Card
      logo={csvLogo} 
      title="CSV Viewer"
      path="/csv-viewer"
      description="View and manage your CSV files."
    />

      <Card
        logo={EmiLogo} 
        title="EMI Calculator"
        path="/emi-calculator"
        description="Calculate your EMI payments easily."
      />
      <Card
        logo={calorieLogo} 
        title="Calorie Calculator"
        path="/calorie-calculator"
        description="Calculate your daily calorie needs."
      />
      <Card
        logo={photoLogo}
        title="Photo Joiner"
        path="/photo-joiner"
        description="Join multiple photos into one image."
      />
      <Card
        logo={macrosLogo}
        title="Macros Calculator"
        path="/macros-calculator"
        description="Calculate your daily macros requirements."
      />
      <Card
        logo={nsLogo}
        title="Name Server Lookup"
        path="/nameserver-lookup"
        description="Find DNS records of a domain"
      />
      <Card
        logo={hostingLogo}
        title="Hosting Finder"
        path="/hosting-finder"
        description="Find Which Web Hosting a Website is using"
      />
      <Card
        logo={statusLogo}
        title="Status Code Finder"
        path="/status-code-finder"
        description="Find A URL's Status Code of a website"
      />
      
       <Card
        logo={snapLogo}
        title="SnapCode Generator"
        path="/snapcode-generator"
        description="Create and find your Snapcodes"
      />
    </div>
  );
};

export default Cards;
