import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SnapcodeGenerator = () => {
  const [username, setUsername] = useState("");
  const [snapcodeUrl, setSnapcodeUrl] = useState("");

  const generateSnapcode = () => {
    if (username) {
      const url = `https://feelinsonice.appspot.com/web/deeplink/snapcode?username=${username}&type=SVG&bitmoji=enable`;
      setSnapcodeUrl(url);
    }
  };

  return (
    
    <div className="flex flex-col items-center">
        <Helmet>
        <title>Snapcode Generator</title>
        <meta
          name="description"
          content="Generate Snapcode of your Profile"
        />
        <meta name="keywords" content="Snapcode Generator" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/snapcode-generator" />
      </Helmet>
      <h2 className="text-4xl mb-20 font-bold">Snapcode Generator</h2>
      <input
        type="text"
        placeholder="Enter Snapchat Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className="border p-2 mb-4"
      />
      <button
        onClick={generateSnapcode}
        className="bg-blue-500 hover:bg-blue-400 font-bold text-white p-2 rounded"
      >
        Generate Snapcode
      </button>
      {snapcodeUrl && (
        <div className="mt-4">
          <h2 className="text-2xl text-center font-bold ">
            Here is your Snapcode :
          </h2>
          <img
            className="bg-white shadow-xl rounded-md px-6 py-6"
            src={snapcodeUrl}
            alt="Snapcode"
          />
        </div>
      )}
    </div>
  );
};

export default SnapcodeGenerator;
