// src/App.js
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import CSVViewer from "./CSVViewer";
import EMICalculator from "./EMICalculator";
import CalorieCalculator from "./CalorieCalculator";
import PhotoJoiner from "./PhotoJoiner";
import MacrosCalculator from "./MacrosCalculator";
import Cards from "./Cards";
import NameserverLookup from "./NameserverLookup";
import CMSFinder from "./CMSFinder";
import HostingFinder from "./HostingFinder";
import FrontPage from "./FrontPage"; // Import the FrontPage component
import StatusCodeFinder from "./StatusCodeFinder"; // Import the component
import SnapcodeGenerator from "./SnapcodeGenerator";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="p-4">
          <Routes>
            <Route
              path="/"
              element={
                <FrontPage 
                className="justify-items-center"
                title="All Tools">
                  <Cards />
                </FrontPage>
              }
            />
            <Route
              path="/csv-viewer"
              element={
                <FrontPage title="CSV Viewer">
                  <CSVViewer />
                </FrontPage>
              }
            />
            <Route
              path="/emi-calculator"
              element={
                <FrontPage title="EMI Calculator">
                  <EMICalculator />
                </FrontPage>
              }
            />
            <Route
              path="/calorie-calculator"
              element={
                <FrontPage title="Calorie Calculator">
                  <CalorieCalculator />
                </FrontPage>
              }
            />
            <Route
              path="/photo-joiner"
              element={
                <FrontPage title="Photo Joiner">
                  <PhotoJoiner />
                </FrontPage>
              }
            />
            <Route
              path="/macros-calculator"
              element={
                <FrontPage title="Macros Calculator">
                  <MacrosCalculator />
                </FrontPage>
              }
            />
        
            <Route
              path="/nameserver-lookup"
              element={
                <FrontPage title="Nameserver Lookup">
                  <NameserverLookup />
                </FrontPage>
              }
            />
          
            <Route
              path="/cms-finder"
              element={
                <FrontPage title="CMS Finder">
                  <CMSFinder />
                </FrontPage>
              }
            />
              <Route
              path="/status-code-finder"
              element={
                <FrontPage title="Status Code Finder">
                  <StatusCodeFinder />
                </FrontPage>
              }
            />
        
            <Route
              path="/hosting-finder"
              element={
                <FrontPage title="Hosting Finder">
                  <HostingFinder />
                </FrontPage>
                
              }
            />
            <Route
              path="/snapcode-generator"
              element={
                <FrontPage>
                  <SnapcodeGenerator />
                </FrontPage>
                
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
