// src/HostingFinder.js
import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const HostingFinder = () => {
  const [url, setUrl] = useState('');
  const [hostingInfo, setHostingInfo] = useState(null);
  const [error, setError] = useState('');

  const API_KEY = 'wn7pwdwb70d78xyry3q1uo57bmpi8rpd318ikcf4wa6vv1xso5117b3cur1nakb2d2clrw'; // Replace with your actual API key

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setHostingInfo(null);

    try {
      const response = await axios.get(`https://www.who-hosts-this.com/API/Host`, {
        params: {
          key: API_KEY,
          url: url,
        },
      });

      setHostingInfo(response.data.results[0]); // Get the first result
    } catch (err) {
      setError('Failed to fetch hosting information. Please try again.');
    }
  };

  return (
    <div>
        <Helmet>
        <title>Web Hosting Finder - Find Web Hosting Details of a Website</title>
        <meta
          name="description"
          content="Find What Web Hosting a website is using."
        />
        <meta name="keywords" content="Web Hosting Finder" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/hosting-finder" />
      </Helmet>
      <h1 className="text-center text-2xl font-bold mb-4 mt-20">
        Web Hosting Finder
      </h1>
      <div className="flex justify-center items-center mb-20">
        <form onSubmit={handleSubmit} className="flex items-center">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter a URL"
            required
            className="border p-2 rounded-l-md w-64"
          />
          <button
            type="submit"
            className="bg-black font-bold text-white px-4 py-2 rounded-r-md shadow-md hover:bg-gray-900 transition-colors"
          >
            Find Hosting
          </button>
        </form>
      </div>

      {error && <p className="text-red-500">{error}</p>}
      {hostingInfo && (
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
          <p className=" text-center mb-10 ml-20 mr-20 bg-indigo-500 px-2 py-2 rounded-md font-bold text-white">
            RECOMMENDED
          </p>
          {/* Affiliate Logo - Placeholder */}
          <img
            src="https://via.placeholder.com/64"
            alt="Hosting Logo"
            className="w-16 h-16 rounded-full mx-auto mb-4"
          />

          {/* Affiliate Information */}
          <h2 className="text-xl font-bold text-center mb-2">
            Hosting Company:{" "}
            <a
              href={`${hostingInfo.isp_name.toLowerCase()}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-600 transition-colors"
            >
              {hostingInfo.isp_name}
            </a>
          </h2>
          <p className="text-gray-600 text-center mb-4">
            IP Address: {hostingInfo.ip}
          </p>

          {/* Affiliate Link */}
          <div className="flex items-center justify-center mb-4">
            <a
              href={`${hostingInfo.isp_name.toLowerCase()}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 font-bold text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition-colors"
            >
              GET HOSTING
            </a>
          </div>

          {/* Affiliate Commission */}
          <div className="text-center">
            <p className="text-2xl font-semibold">
              Plans as low as $5 per month
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HostingFinder;
