import React, { useState } from "react";
import { Helmet } from "react-helmet";

function StatusCodeFinder() {
  const [url, setUrl] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [error, setError] = useState(null);

  const checkStatusCode = async () => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      setStatusCode(response.status);
      setError(null);
    } catch (err) {
      setError("Failed to fetch the status code. Please check the URL.");
      setStatusCode(null);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white rounded shadow">
      <Helmet>
        <title>Status Code Finder</title>
        <meta
          name="description"
          content="Check the HTTP status code of any website quickly and easily."
        />
        <meta name="keywords" content="HTTP, Status Code, URL, Checker, Web Development" />
        <meta name="author" content="Your Name" />
        <link rel="canonical" href="https://www.yourwebsite.com/status-code-finder" />
      </Helmet>
      <h2 className="text-xl font-bold mb-4">Status Code Finder</h2>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter URL"
        className="border border-gray-300 p-2 w-full mb-4"
      />
      <button
        onClick={checkStatusCode}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Check Status Code
      </button>
      {statusCode && (
        <p className="mt-4 text-green-600">Status Code: {statusCode}</p>
      )}
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </div>
  );
}

export default StatusCodeFinder;
