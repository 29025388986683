// src/NameserverLookup.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const NameserverLookup = () => {
  const [domain, setDomain] = useState('');
  const [results, setResults] = useState({
    A: [],
    MX: [],
    NS: [],
    TXT: [],
    SPF: []
  });
  const [error, setError] = useState('');

  const handleLookup = async () => {
    setError('');
    setResults({
      A: [],
      MX: [],
      NS: [],
      TXT: [],
      SPF: []
    });

    // Normalize the domain input by stripping protocol
    const normalizedDomain = domain.replace(/^https?:\/\//, '').replace(/\/.*$/, '');

    const recordTypes = ['A', 'MX', 'NS', 'TXT', 'SPF'];
    try {
      for (const type of recordTypes) {
        const response = await fetch(`https://dns.google/resolve?name=${normalizedDomain}&type=${type}`);
        const data = await response.json();
        if (data.Answer) {
          setResults(prevResults => ({
            ...prevResults,
            [type]: data.Answer.map(record => record.data || record.value)
          }));
        }
      }
    } catch (e) {
      setError('Error fetching DNS records.');
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4">
        <Helmet>
        <title>DNS Record Lookup - Lookup NameServers, IP Address, CNAMES etc</title>
        <meta
          name="description"
          content="Find IP Address, A Records, CNAME and many more records."
        />
        <meta name="keywords" content="DNS Record Lookup - A Records, CNAME, IP Address and Many More" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/nameserver-lookup" />
      </Helmet>
      <h1 className="text-xl font-bold mb-4 text-center">DNS Record Lookup</h1>
      <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0 mb-4 justify-center">
        <input
          type="text"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          placeholder="Enter domain name or URL"
          className="p-2 border border-gray-300 rounded w-full max-w-md"
        />
        <button
          onClick={handleLookup}
          className="bg-blue-500 text-white p-2 rounded w-full md:w-auto"
        >
          Lookup
        </button>
      </div>
      {error && <div className="text-red-500 mt-2 text-center">{error}</div>}
      
      {Object.entries(results).map(([type, records]) => (
        <div key={type} className="mt-4 p-4 border rounded shadow-md">
          <h2 className="text-lg font-semibold mb-2">{type} Records</h2>
          {records.length > 0 ? (
            <ul>
              {records.map((record, index) => (
                <li key={index} className="border-b py-2">{record}</li>
              ))}
            </ul>
          ) : (
            <p>No {type} records found.</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default NameserverLookup;
