// src/CMSFinder.js
import React, { useState } from 'react';
import axios from 'axios';

function CMSFinder() {
  const [url, setUrl] = useState('');
  const [cms, setCms] = useState('');
  const [error, setError] = useState('');

  const findCMS = async () => {
    setCms('');
    setError('');

    try {
      const response = await axios.get(url, { timeout: 5000 });
      const data = response.data;

      if (data.includes('wp-content') || data.includes('wp-includes')) {
        setCms('WordPress');
      } else if (data.includes('<meta name="generator" content="Joomla!')) {
        setCms('Joomla');
      } else if (data.includes('<meta name="generator" content="Drupal')) {
        setCms('Drupal');
      } else if (data.includes('site/templates/')) {
        setCms('Concrete5');
      } else if (data.includes('<meta name="generator" content="Magento')) {
        setCms('Magento');
      } else {
        setCms('Unknown CMS or Custom Solution');
      }
    } catch (err) {
      setError('Failed to detect CMS. Ensure the URL is correct and reachable.');
      console.error('Error detecting CMS:', err);
    }
  };

  return (
    <div>
      <h1>CMS Finder</h1>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter website URL"
        className="border p-2 mb-2"
      />
      <button onClick={findCMS} className="bg-blue-500 text-white p-2">
        Find CMS
      </button>

      {cms && (
        <div className="mt-4">
          <p>Detected CMS: {cms}</p>
        </div>
      )}

      {error && (
        <div className="mt-4 text-red-500">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default CMSFinder;
