import React, { useState } from 'react';
import Papa from 'papaparse';
import { Helmet } from 'react-helmet';

const CSVViewer = () => {
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [columnIndexes, setColumnIndexes] = useState([]); // State to track which columns to display
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Handle file upload and parse CSV
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const [headerRow, ...rows] = result.data;
          setHeaders(headerRow);
          setCsvData(rows);
          setColumnIndexes(headerRow.map((_, index) => index)); // Show all columns by default
        },
        header: false,
      });
    }
  };

  // Sorting function
  const handleSort = (columnIndex) => {
    let direction = 'ascending';
    if (sortConfig.key === columnIndex && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...csvData].sort((a, b) => {
      const aVal = a[columnIndex] || ''; // Handle undefined or empty values
      const bVal = b[columnIndex] || '';

      const isNumeric = !isNaN(aVal) && !isNaN(bVal);
      if (isNumeric) {
        return direction === 'ascending'
          ? parseFloat(aVal) - parseFloat(bVal)
          : parseFloat(bVal) - parseFloat(aVal);
      }

      return direction === 'ascending'
        ? aVal.toString().localeCompare(bVal.toString())
        : bVal.toString().localeCompare(aVal.toString());
    });

    setSortConfig({ key: columnIndex, direction });
    setCsvData(sortedData);
  };

  // Handle column selection from dropdown
  const handleColumnSelection = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(option => option.value);
    if (selectedOptions.includes('all')) {
      // Show all columns
      setColumnIndexes(headers.map((_, index) => index));
    } else {
      // Show selected columns
      setColumnIndexes(selectedOptions.map(value => parseInt(value)));
    }
  };

  // Export the filtered table as CSV
  const handleExport = () => {
    const selectedHeaders = columnIndexes.map((index) => headers[index]);
    const selectedRows = csvData.map((row) =>
      columnIndexes.map((colIndex) => row[colIndex])
    );

    const csvContent = [
      selectedHeaders.join(','), // Add headers
      ...selectedRows.map((row) => row.join(',')), // Add rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'filtered_table.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
       <Helmet>
        <title>CSV Viewer</title>
        <meta
          name="description"
          content="View, Sort and Download CSV."
        />
        <meta name="keywords" content="CSV Viewer" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/csv-viewer" />
      </Helmet>
      <h1 className="text-3xl text-center mb-4">CSV Viewer</h1>
      <div className="mx-auto my-auto mb-4">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block mx-auto p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>

      {headers.length > 0 && (
        <div className="mb-4 text-center">
          <h3 className="mb-2 text-lg font-bold">Select Columns to Display:</h3>
          <select
            multiple
            onChange={handleColumnSelection}
            className="items-center justify-between rounded bg-white p-2 ring-1 ring-gray-300"
            value={columnIndexes}
          >
            <option value="all">Show All Columns</option>
            {headers.map((header, index) => (
              <option key={index} value={index}>
                {`${index}: ${header}`}
              </option>
            ))}
          </select>
        </div>
      )}

      {csvData.length > 0 && columnIndexes.length > 0 && (
        <div className="relative"> {/* Added relative positioning */}
          <div className="sticky top-0 z-10 bg-white mb-2"> {/* Sticky positioning and margin */}
            <div className="flex justify-end sm:justify-center"> {/* Align to right on large screens, center on small screens */}
              <button
                onClick={handleExport}
                className="bg-black font-semibold text-white px-4 py-2 rounded"
              >
                Download Table
              </button>
            </div>
          </div>
          <div className="mx-auto max-h-[60vh]"> {/* Container for scrolling */}
            <table className="min-w-full border-collapse border border-gray-400">
              <thead className="sticky top-0 bg-gray-200 z-20"> {/* Sticky header */}
                <tr>
                  {columnIndexes.map((index) => (
                    <th
                      key={index}
                      onClick={() => handleSort(index)}
                      className="border border-gray-300 px-4 py-2 cursor-pointer truncate"
                    >
                      {headers[index]}
                      {sortConfig.key === index && (
                        <span>
                          {sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽'}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnIndexes.map((colIndex) => (
                      <td
                        key={colIndex}
                        className="border border-gray-300 px-4 py-2 truncate"
                      >
                        {row[colIndex] || ''}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CSVViewer;
