import React, { useState } from 'react';
import { Helmet } from 'react-helmet';


const CalorieCalculator = () => {
  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [gender, setGender] = useState('male');
  const [activityLevel, setActivityLevel] = useState('sedentary');
  const [calories, setCalories] = useState(null);
  const [caloriesForFatLoss, setCaloriesForFatLoss] = useState(null);
  const [caloriesForGain, setCaloriesForGain] = useState(null);
  const [ageError, setAgeError] = useState('');
  const [weightUnit, setWeightUnit] = useState('kg');
  const [heightUnit, setHeightUnit] = useState('cm');

  const validateAge = (age) => {
    if (age < 13 || age > 80) {
      setAgeError('Input age between 13 and 80');
    } else {
      setAgeError('');
    }
  };

  const handleAgeChange = (e) => {
    const value = e.target.value;
    setAge(value);
    validateAge(value);
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const calculateCalories = () => {
    if (!age || !weight || !height) {
      alert('Please fill out all required fields.');
      return;
    }
  
    if (ageError) {
      return;
    }
  
    let bmr;
    let weightInKg = weightUnit === 'kg' ? parseInt(weight, 10) : Math.round(parseInt(weight, 10) * 0.454);
    let heightInCm;
  
    if (heightUnit === 'cm') {
      heightInCm = parseInt(height, 10);
    } else {
      // Convert height from feet and inches to centimeters
      const [feet, inches] = height.split('-').map((value) => parseInt(value, 10) || 0);
      heightInCm = Math.round(feet * 30 + inches * 3);
    }
  
    if (gender === 'male') {
      bmr = Math.round(10 * weightInKg + 6.25 * heightInCm - 5 * age + 5);
    } else {
      bmr = Math.round(10 * weightInKg + 6.25 * heightInCm - 5 * age - 161);
    }
  
    let multiplier;
  
    switch (activityLevel) {
      case 'sedentary':
        multiplier = 1.2;
        break;
      case 'light':
        multiplier = 1.375;
        break;
      case 'moderate':
        multiplier = 1.55;
        break;
      case 'active':
        multiplier = 1.725;
        break;
      case 'very-active':
        multiplier = 1.9;
        break;
      default:
        multiplier = 1.2;
    }
  
    const dailyCalories = Math.round(bmr * multiplier);
    setCalories(dailyCalories);
    setCaloriesForFatLoss(dailyCalories - 500);
    setCaloriesForGain(dailyCalories + 500);
  };
  

  return (
    <div className="p-4 max-w-lg mx-auto">
        <Helmet>
        <title>Calore Calculator</title>
        <meta
          name="description"
          content="Calculate your Daily Required Calories"
        />
        <meta name="keywords" content="Calories Calculator" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/calorie-calculator" />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4">Calorie Calculator</h2>
      <div className="mb-4">
        <label className="block mb-2">Age</label>
        <input
          type="number"
          value={age}
          onChange={handleAgeChange}
          className={`w-full p-2 border border-gray-300 rounded ${ageError ? 'border-red-500' : ''}`}
          placeholder="Age in years"
          required
          min="13"
          max="80"
        />
        {ageError && <p className="text-red-500 text-sm mt-1">{ageError}</p>}
      </div>
      <div className="mb-4">
        <label className="block mb-2">Weight</label>
        <div className="flex items-center mb-2">
          <input
            type="radio"
            id="weight-kg"
            name="weight-unit"
            value="kg"
            checked={weightUnit === 'kg'}
            onChange={() => setWeightUnit('kg')}
            className="mr-2"
          />
          <label htmlFor="weight-kg" className="mr-4">Kg</label>
          <input
            type="radio"
            id="weight-lbs"
            name="weight-unit"
            value="lbs"
            checked={weightUnit === 'lbs'}
            onChange={() => setWeightUnit('lbs')}
            className="mr-2"
          />
          <label htmlFor="weight-lbs">Lbs</label>
        </div>
        <input
          type="number"
          value={weight}
          onChange={handleWeightChange}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder={`Weight in ${weightUnit}`}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Height</label>
        <div className="flex items-center mb-2">
          <input
            type="radio"
            id="height-cm"
            name="height-unit"
            value="cm"
            checked={heightUnit === 'cm'}
            onChange={() => setHeightUnit('cm')}
            className="mr-2"
          />
          <label htmlFor="height-cm" className="mr-4">Cm</label>
          <input
            type="radio"
            id="height-feet-in"
            name="height-unit"
            value="feet-in"
            checked={heightUnit === 'feet-in'}
            onChange={() => setHeightUnit('feet-in')}
            className="mr-2"
          />
          <label htmlFor="height-feet-in">Feet & Inches</label>
        </div>
        {heightUnit === 'cm' ? (
          <input
            type="number"
            value={height}
            onChange={handleHeightChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Height in cm"
            required
          />
        ) : (
          <div className="flex space-x-2">
            <input
              type="number"
              value={height.split('-')[0] || ''}
              onChange={(e) => setHeight(`${e.target.value}-${height.split('-')[1] || ''}`)}
              className="w-1/2 p-2 border border-gray-300 rounded"
              placeholder="Feet"
              required
            />
            <input
              type="number"
              value={height.split('-')[1] || ''}
              onChange={(e) => setHeight(`${height.split('-')[0] || ''}-${e.target.value}`)}
              className="w-1/2 p-2 border border-gray-300 rounded"
              placeholder="Inches"
              required
            />
          </div>
        )}
      </div>
      <div className="mb-4">
        <label className="block mb-2">Gender</label>
        <select
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Activity Level</label>
        <select
          value={activityLevel}
          onChange={(e) => setActivityLevel(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="sedentary">Sedentary (little or no exercise)</option>
          <option value="light">Lightly active (light exercise/sports 1-3 days/week)</option>
          <option value="moderate">Moderately active (moderate exercise/sports 3-5 days/week)</option>
          <option value="active">Very active (hard exercise/sports 6-7 days a week)</option>
          <option value="very-active">Extra active (very hard exercise/sports and a physical job)</option>
        </select>
      </div>
      <button
        onClick={calculateCalories}
        className="bg-blue-500 text-white p-2 rounded"
        disabled={!!ageError}
      >
        Calculate
      </button>
      {(calories !== null || caloriesForFatLoss !== null || caloriesForGain !== null) && (
        <div className="mt-4 p-4 border border-gray-300 rounded shadow">
          <h3 className="text-xl font-bold mb-10">Caloric Needs</h3>
          <div className="mb-10">
            <strong className='text-xl'>Maintenance: </strong> <span className='bg-sky-400 text-white px-3 py-2 font-semibold text-2xl'>{calories ? calories : 'N/A'} </span>
          </div>
          <div className="mb-10">
            <strong className='text-xl'>Calories For Fat Loss: </strong> <span className='bg-slate-800 text-white px-3 py-2 font-semibold text-2xl'>{caloriesForFatLoss ? caloriesForFatLoss : 'N/A'} </span>
          </div>
          <div className="mb-10">
            <strong className='text-xl'>Calories For Gain:</strong> <span className='bg-red-500 text-white px-3 py-2 font-semibold text-2xl'>{caloriesForGain ? caloriesForGain : 'N/A'} </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalorieCalculator;
