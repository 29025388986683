// src/BlogPost.js
import React from 'react';

const FrontPage = ({ title, children }) => {
  return (
    <div className="max-w-4xl mx-auto p-4 rounded-lg my-10">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">{title}</h1>
      <div className="prose prose-lg text-gray-700">
        {children}
      </div>
    </div>
  );
};

export default FrontPage;
