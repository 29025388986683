import React, { useState } from "react";
import { Helmet } from "react-helmet";

function PhotoJoiner() {
  const [photos, setPhotos] = useState([]);
  const [orientation, setOrientation] = useState("horizontal");
  const [padding, setPadding] = useState(0); // Space between images
  const [margin, setMargin] = useState(0); // Space around the images
  const [joinedImage, setJoinedImage] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setPhotos(files);
  };

  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
  };

  const handlePaddingChange = (e) => {
    setPadding(parseInt(e.target.value, 10) || 0);
  };

  const handleMarginChange = (e) => {
    setMargin(parseInt(e.target.value, 10) || 0);
  };

  const joinPhotos = () => {
    if (photos.length === 0) return;

    const images = photos.map((file) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          resolve(img);
        };
      });
    });

    Promise.all(images).then((imgs) => {
      let totalWidth = margin * 2; // Initialize with left and right margin
      let totalHeight = margin * 2; // Initialize with top and bottom margin

      imgs.forEach((img, index) => {
        if (orientation === "horizontal") {
          totalWidth += img.width; // Add each image's width
          if (index > 0) totalWidth += padding; // Add padding between images
          totalHeight = Math.max(totalHeight, img.height + margin * 2); // Adjust height if needed
        } else {
          totalHeight += img.height; // Add each image's height
          if (index > 0) totalHeight += padding; // Add padding between images
          totalWidth = Math.max(totalWidth, img.width + margin * 2); // Adjust width if needed
        }
      });

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = totalWidth;
      canvas.height = totalHeight;

      let x = margin;
      let y = margin;

      imgs.forEach((img) => {
        ctx.drawImage(img, x, y);
        if (orientation === "horizontal") {
          x += img.width + padding;
        } else {
          y += img.height + padding;
        }
      });

      const dataURL = canvas.toDataURL("image/png");
      setJoinedImage(dataURL);
    });
  };

  const downloadImage = () => {
    if (!joinedImage) return;

    const link = document.createElement("a");
    link.href = joinedImage;
    link.download = "joined-image.png";
    link.click();
  };

  const removePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const handleDragStart = (index) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggingIndex === null || draggingIndex === index) return;

    const newPhotos = [...photos];
    const draggedPhoto = newPhotos[draggingIndex];

    newPhotos.splice(draggingIndex, 1);
    newPhotos.splice(index, 0, draggedPhoto);

    setPhotos(newPhotos);
    setDraggingIndex(null);
  };

  return (
    <div className="flex flex-col items-center p-4">
        <Helmet>
        <title>Photo Joiner - Join Photos Instantly</title>
        <meta
          name="description"
          content="Join Photos Instantly"
        />
        <meta name="keywords" content="Photo Joiner Online" />
        <meta name="author" content="Sameer" />
        <link rel="canonical" href="https://tools.sameer.li/photo-joiner" />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Photo Joiner</h1>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        className="mb-4 rounded-md ring-slate-200 ring-2 px-2 py-2"
      />
      <p className="mb-2"> Alignment : </p>
      <select
        onChange={handleOrientationChange}
        value={orientation}
        className="px-2 py-2 rounded-sm ring-slate-200 ring-2 px-3 py-3 bg-gray-100 mb-4"
      >
        <option value="horizontal">Horizontal</option>
        <option value="vertical">Vertical</option>
      </select>
      <div className="my-4">
        <label className="block mb-2">
          Margin (px):
          <input
            type="number"
            value={margin}
            onChange={handleMarginChange}
            className="ml-2 p-1 border border-gray-300 rounded"
          />
        </label>
        <label className="block mb-2">
          Padding (px):
          <input
            type="number"
            value={padding}
            onChange={handlePaddingChange}
            className="ml-2 p-1 border border-gray-300 rounded"
          />
        </label>
      </div>
      <button
        onClick={joinPhotos}
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
      >
        Join Photos
      </button>
      {photos.length > 0 && (
        <div className="my-4">
          <h2 className="text-xl font-semibold mb-2">Photo Previews</h2>
          <div
            className={`flex ${
              orientation === "horizontal" ? "flex-row" : "flex-col"
            } gap-2 p-4 border border-gray-300 rounded-md justify-center`}
          >
            {photos.map((file, index) => (
              <div
                key={index}
                className="relative w-24 h-24"
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Preview ${index}`}
                  className="w-full h-full object-cover border"
                />
                <div className="absolute top-0 right-0">
                  <div
                    onClick={() => removePhoto(index)}
                    className="bg-red-500 text-white text-xs rounded-full w-6 h-6 flex items-center justify-center shadow-lg hover:bg-red-600 focus:outline-none"
                    style={{ transform: "translate(50%, -50%)" }}
                  >
                    <span className="text-full font-bold cursor-pointer">X</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {joinedImage && (
        <div className="my-4">
          <img
            src={joinedImage}
            alt="Joined"
            className="w-full max-w-xl border border-gray-300 rounded-md"
          />
          <button
            onClick={downloadImage}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
          >
            Download Joined Image
          </button>
        </div>
      )}
    </div>
  );
}

export default PhotoJoiner;
